export default {
  proxy_path: "https://b3d79yg32h.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://rfiif5t9r6.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyB9O0BHfLu42XEe_50uDzubnJzPHFI0m6s",
  main_company_term_id: "9593",
  crm_base_url: "evolution.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.evolution.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.evolution.forwoodsafety.com",
    APP_CLIENT_ID: "7o2skfo400s9ti7c75i62efd77",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://q1aow6rwbc.execute-api.us-west-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.evolution.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
